body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.basic_box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vert_box{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.basic_back {
    height: 100%;
    width: 100%
}

.card_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    padding-bottom: 3%;
    width: 100%;
    font-size: 1.8rem;
    color: #333333;
    font-weight: 600;
}

.imageBack {
    width: 100%;
    height: 100%;
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background: #ffffff !important;
    color: #333333 !important;
}
.ant-menu-dark .ant-menu-item{
    color: inherit !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected{
    background-color: #F56E00 !important;
    color: #ffffff !important;
}
.ant-dropdown-menu-item{
    font-size: 16px;
    padding: 5px 8px;
}
.ant-dropdown-menu-item:hover{
    background-color: #F56E00 !important;
    color: #ffffff !important;
}
.ant-dropdown-menu-item > a{
    color: inherit !important;
}

.header_box {
    height: 64px;
    display: flex;
    width: 100%;
    top: 0;
}

.header_tab {
    display: flex;
    margin-right: 10%;
    margin-left: auto;
}

.header_tab_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1%;
    min-width: 80px;
    font-size: 1rem;
}

.header_line {
    width: 32px;
    height: 3px;
    background-color: #F56E00;
    margin-top: 5px;
}

.carousel_img {
    width: 100%;
    /*height: 15rem;*/
}

.home1_back {
    width: 100%;
    display: flex;
    font-size: 1.8rem;
    color: #333333;
    font-weight: 600;
    flex-direction: column;
    align-items: center;
    margin: 3% 0;
}
.home_information{
    margin-right: auto;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:1;
    padding-right: 20px;
}
.notice_box{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    padding: 2% 0;
    width: 46%;
    margin-right: 4%;
    border-bottom: 1px solid rgba(230,232,231,0.5);
}
.notice_box:hover{
    color: rgba(245, 110, 0, 1);
}
.notice_box:nth-child(2n+2) {
    margin-left: 4%;
    margin-right: 0;
}
.footer_box{
    width: 100%;
    height: 18rem;
    background-color: #272220;
    color: #999999;
    padding: 5% 10%;
}
.footer_title{
    color: #FFFFFF;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 2%;
}
.footer_line{
    width:1px;
    height:88px;
    background:rgba(201,157,130,0.2);
    border:1px solid rgba(255,255,255,0.2);
    margin: 0 10% 0 20%;
    transform:scaleX(0.5);
}
.information_box{
    margin: 5%;
    width: 90%;
    display: inline-block;
}
.information_card{
    width: 48%;
    padding: 3% 5%;
    margin: 0 1% 2% 1%;
    float: left;
    background:rgba(255,255,255,1);
    border:1px solid rgba(254,245,236,1);
    box-shadow:0 6px 9px 0 rgba(148,128,107,0.07);
}
.information_title{
    color: #333333;
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 2%;
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:1;
}
.information_text{
    overflow:hidden;
    text-overflow:ellipsis;
    display:-webkit-box;
    color: #666666;
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
    min-height: 42px;
}
.detail_title{
    color: #333333;
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 5%;
}
.detail_line{
    background-color: rgba(209,209,209,0.42);
    width: 100%;
    height: 1px;
}
.about_btn{
    width: 5.5rem;
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    margin: 3% 0 10% 0;
    background:rgba(255,127,0,1);
    border-radius:2px;
    color: white;
}
.about_btn:hover{
    color: #ffffff !important;
}
.information_card:hover{
    background-color: #f5f4f3;
    box-shadow: none;
}
.bottom_text{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 25px;
}
